import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  handleChange(event) {
    const checkbox = event.currentTarget

    this.setRowSelection(checkbox)
  }

  setRowSelection(checkbox) {
    if (checkbox.checked) {
      checkbox.closest(".table-row").classList.add("selected")
    } else {
      checkbox.closest(".table-row").classList.remove("selected")
    }
  }
}
