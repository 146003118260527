import { ApplicationController, useDebounce } from 'stimulus-use'
import Fuse from 'fuse.js'
import { turboSubmitForm } from '~/utils/form_turbo_submit.js'

export default class extends ApplicationController {
  static debounces = ["search"]
  static targets = ["button", "list", "item", "searchInput", "resetButton", "buttonText", "chip",
                    "selectAllContainer", "selectAllLabel", "deselectAllLabel"]
  static values = {
    withSelectionCounter: Boolean,
    isMultiple: Boolean,
    initialTitle: String,
    buttonStyle: String,
    withSelectedStateColor: Boolean,
    freezeTitle: Boolean
  }

  connect() {
    useDebounce(this, {wait: 200})
    this.setSelectStyle()
    this.displayResetButton()
  }

  // Event handlers

  toggleList(event) {
    // currentTarget will always be the dropdown button
    // target can either be the button or a list item
    if (this.activeFilter === event.target.id) {
      event.currentTarget.blur()
      this.hideList(event)
    } else if (this.listTarget.classList.contains('invisible')) {
      this.listTarget.classList.remove('invisible')
      this.listTarget.classList.add('z-10')
      this.activeFilter = event.currentTarget.id
      if (this.hasSearchInputTarget) {
        this.searchInputTarget.focus()
      }
    }
  }

  hideList(event = null) {
    // Don't hide if the filter is searchable, but hide if the button receiving focus is from a different filter
    if (this.hasSearchInputTarget && event?.relatedTarget) {
      const differentButtonFocused = event.relatedTarget.type === 'button' && event.relatedTarget !== this.buttonTarget
      const searchInputFocused = [event.relatedTarget, event.target].includes(this.searchInputTarget)
      if (searchInputFocused && !differentButtonFocused) {
        return
      }
    }
    // Don't hide if one of the filter's inputs/list items receives focus
    const filterInputFocused = this.hasSelectableInputTarget && event?.relatedTarget && this.selectableInputTargets.includes(event.relatedTarget)
    const itemFocused = event?.relatedTarget && this.itemTargets.includes(event.relatedTarget)
    const selectAllFocused = event?.relatedTarget && this.hasSelectAllContainerTarget && event.relatedTarget === this.selectAllContainerTarget
    if (filterInputFocused || itemFocused || selectAllFocused) {
      return
    }

    this.listTarget.classList.add('invisible')
    this.listTarget.classList.remove('z-10')
    this.activeFilter = null
  }

  search(event) {
    const query = event.target.value
    if (query.length === 0) { return this.showAll() }

    const items = this.itemTargets
    const options = {
      keys: ['innerText'],
      useExtendedSearch: true,
      threshold: 0.3,
      ignoreLocation: true
    }

    const search = new Fuse(items, options).search(query)
    const filteredItems = search.map(result => result.item)

    items.forEach((item) => {
      if (filteredItems.includes(item)) {
        item.classList.remove('hidden')
      } else {
        item.classList.add('hidden')
      }
    })
    this.handleSelectAllDisplay()
  }

   navigateList(event) {
    const keyPressed = event.key
    switch (keyPressed) {
      case 'Enter':
        event.preventDefault()
        // While the search input is focused, focus the first item, but don't check it
        if (this.hasSearchInputTarget && document.activeElement === this.searchInputTarget) {
          this.focusFirstItem()
        } else {
          this.selectItem({currentTarget: document.activeElement})
        }
        break
      case 'Escape':
        event.preventDefault()
        this.buttonTarget.blur()
        this.hideList(event)
        break
      case 'ArrowUp':
      case 'ArrowDown':
        event.preventDefault()
        this.navigate(keyPressed)
        break
      case 'ArrowRight':
      case 'ArrowLeft':
        event.preventDefault()
        this.switchSelect(keyPressed)
        break
    }
  }

  reset() {
    this.hideList()
    this.itemTargets.forEach(item => item.querySelector('input').checked = false)
    this.itemTarget.querySelector('input').dispatchEvent(new Event('change', { bubbles: true, composed: true }))
    this.refreshSelectStyle()
  }

  refreshSelectStyle() {
    this.displayResetButton()
    this.setSelectStyle()
    this.handleSelectAllDisplay()

    if (!this.freezeTitleValue) {
      this.updateSelectTitle()
    }
    window.dispatchEvent(new Event('display-filter-tags', { bubbles: true, composed: true }))
  }

  // Helpers

  showAll() {
    this.itemTargets.forEach((item) => {
      item.classList.remove('hidden')
    })
    this.handleSelectAllDisplay()
  }

  navigate(direction) {
    const focusedItemIndex = this.displayedItems.findIndex(item => item === document.activeElement)

    if (!focusedItemIndex && focusedItemIndex !== 0) {
      return this.focusFirstItem()
    }

    if (direction === 'ArrowUp') {
      this.focusItem(focusedItemIndex - 1, focusedItemIndex)
    } else if (direction === 'ArrowDown') {
      this.focusItem(focusedItemIndex + 1, focusedItemIndex)
    }
  }

  switchSelect(direction) {
    let nextFocusableElement
    if (direction === 'ArrowRight' && this.element.nextElementSibling) {
       nextFocusableElement = this.element.nextElementSibling.querySelector("button")
    } else if (direction === 'ArrowLeft' && this.element.previousElementSibling) {
      nextFocusableElement = this.element.previousElementSibling.querySelector("button")
    }
    if (!nextFocusableElement) { return }

    nextFocusableElement?.click()
    nextFocusableElement?.focus()
    this.buttonTarget.blur()
    this.hideList()
  }

  focusItem(itemIndex, previousItemIndex = null) {
    if (this.hasSearchInputTarget && !this.displayedItems[itemIndex]) {
      return this.searchInputTarget.focus()
    } else if (!this.displayedItems[itemIndex]) {
      return
    }

    const item = this.displayedItems[itemIndex]
    const previousItem = this.displayedItems[previousItemIndex]
    item.focus({focusVisible: true})
    item.tabIndex = 0
    if (previousItem) {
      previousItem.tabIndex = -1
    }
  }

  selectItem(event) {
    const input = event.currentTarget.querySelector('input')
    const checked_state = !input.checked
    this.selectInput(input, checked_state)
  }

  selectInput(input, checked_state) {
    input.checked = checked_state
    input.dispatchEvent(new Event('change', { bubbles: true, composed: true }))
    if (this.hasSearchInputTarget) {
      this.searchInputTarget.focus()
    }
  }

  focusFirstItem() {
    this.focusItem(0)
  }

  get displayedItems() {
    return this.itemTargets.filter(item => !item.classList.contains('hidden'))
  }

  displayResetButton() {
    if (!this.hasResetButtonTarget) return

    if (this.itemTargets.some(item => item.querySelector('input').checked == true)) {
      this.resetButtonTarget.classList.remove('hidden')
    } else {
      this.resetButtonTarget.classList.add('hidden')
    }
  }

  submit(event) {
    turboSubmitForm(event.target.form)
  }

  // setSelectStyle

  setSelectStyle() {
    if (this.isMultiple() && this.withSelectionCounter()) {
      this.displaySelectionCounter()
    }
    this.setSelectChipStyle()
  }

  updateSelectTitle() {
    const selectedInput = this.element.querySelector('input:checked')
    const newTitle = selectedInput?.dataset?.labelAsTitle || selectedInput?.parentNode?.innerText || this.initialTitleValue

    this.buttonTextTarget.innerText = newTitle
  }

  displaySelectionCounter() {
    let baseTitle = this.buttonTextTarget.innerText.replace(/\s*\([^)]*\)/, '') // remove counter from existing title

    let newTitle
    if (this.checkedInputsCount() === 0) {
      newTitle = baseTitle
    } else {
      newTitle = `${baseTitle} (${this.checkedInputsCount()})`
    }

    this.buttonTextTarget.innerText = newTitle
  }

  setSelectChipStyle() {
    if (!this.hasChipTarget) return
    if (this.withSelectedStateColorValue) {
      this.chipTarget.classList.toggle('selected-chip', this.checkedInputsCount() > 0)
    }
  }

  checkedInputsCount() {
    return this.element.querySelectorAll('input:checked').length
  }

  isMultiple() {
    return this.hasIsMultipleValue && this.isMultipleValue
  }

  withSelectionCounter() {
    return this.hasWithSelectionCounterValue && this.withSelectionCounterValue
  }

  // Select All / deselect All
  toggleSelectAll() {
   const shouldAllItemsBeChecked = this.shouldAllItemsBeChecked()
   this.visibleItems().forEach(item => {
      const input = item.querySelector('input')
      this.selectInput(input, shouldAllItemsBeChecked)
    })
    this.handleSelectAllDisplay()
    this.setSelectStyle()
  }

  handleSelectAllDisplay() {
    if (!this.hasSelectAllContainerTarget) return

    const shouldAllItemsBeChecked = this.shouldAllItemsBeChecked()

    this.selectAllLabelTarget.classList.toggle('hidden', !shouldAllItemsBeChecked)
    this.deselectAllLabelTarget.classList.toggle('hidden', shouldAllItemsBeChecked)
  }

  selectAllContainerTargetConnected() {
    this.handleSelectAllDisplay()
  }

  visibleItems() {
    return this.itemTargets.filter(item => !item.classList.contains('hidden'))
  }

  shouldAllItemsBeChecked() {
    return this.visibleItems().some(item => !item.querySelector('input').checked)
  }
}
