import { Controller } from "stimulus"
import SlimSelect from "slim-select"
import "slim-select/styles"
import "./multiple_select_component_controller.css"

export default class extends Controller {
  static values = {
    placeholder: String,
    searchPlaceholder: String,
    noSearchResult: String,
    selected: { type: Array, default: [] }
   }

  connect() {
    const selectAlreadyExist = this.element.dataset.ssid
    if (!selectAlreadyExist) {
      this.select = new SlimSelect({
        select: this.element,
        settings: {
          showSearch: true,
          searchText: this.noSearchResultValue,
          searchPlaceholder: this.searchPlaceholderValue,
          closeOnSelect: false,
          allowDeselect: true,
          placeholderText: this.placeholderValue
        },
      })

      this.select.setSelected(this.#selectedValueAsStrings())
    }
  }

  reset() {
    this.select.setSelected([])
  }

  #selectedValueAsStrings() {
    return this.selectedValue.map(selectedItemId => selectedItemId.toString())
  }
}
