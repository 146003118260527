import { ApplicationController } from 'stimulus-use'
import { turboSubmitForm } from '~/utils/form_turbo_submit.js'
import arrowUp from '~/../assets/images/pictos/arrow-up.svg';
import arrowDown from '~/../assets/images/pictos/arrow-down.svg';

const orderingInputLeftIcons = {
  'arrow-up': arrowUp,
  'arrow-down': arrowDown
}

export default class extends ApplicationController {
  static targets = ["resetChip"]

  displayResetChip() {
    if (!this.hasResetChipTarget) {
      return
    }

    if (this.checkedFilters() || this.activeSearch()) {
        this.resetChipTarget.classList.remove('hidden')
      } else {
        this.resetChipTarget.classList.add('hidden')
      }
  }

  checkedFilters() {
     return Array.from(this.filterCheckboxes).some(item => item.checked == true)
  }

  activeSearch() {
    if (this.searchInput === null) {
      return false
    }

    return this.searchInput.value !== ""
  }

  formSubmit() {
    const form = this.element

    turboSubmitForm(form)
    this.displayResetChip()
  }

  reset() {
    this.resetFilters()
    this.resetSearchInput()

    this.displayResetChip()
  }

  resetFilters() {
    this.resetCheckboxes()
    this.resetSearchInputs()
  }

  resetCheckboxes() {
    this.filterCheckboxes.forEach(item => {
      item.checked = false
      this.notifyReset(item)
    })
  }

  resetSearchInputs() {
    this.filterSearchInputs.forEach(item => {
      item.value = ""
      this.notifyReset(item)
    })
  }

  resetSearchInput() {
    if (this.searchInput === null) {
      return
    }

    this.searchInput.value = ""
    this.notifyReset(this.searchInput, 'input')
  }

  notifyReset(input, type = 'change') {
    input.dispatchEvent(new Event(type, { bubbles: true, composed: true }))
  }

  changeLeftIcon(event) {
    const pictoName = event.currentTarget.dataset.leftIcon
    const newPictoUrl = orderingInputLeftIcons[pictoName]
    const svgContainer = this.element.querySelector('svg')

    fetch(newPictoUrl)
      .then(response => response.text())
      .then(data => {
              svgContainer.innerHTML = data
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  get filterCheckboxes() {
    return this.element.querySelectorAll(".custom-select input[type='checkbox']");
  }

  get filterSearchInputs() {
    return this.element.querySelectorAll(".custom-select input[type='search']");
  }

  get searchInput() {
    return this.element.querySelector("input[name*='[search]']")
  }
}
