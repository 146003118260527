import { Controller } from "stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ["itemInput",
                    "counter",
                    "headerCheckbox",
                    "topControlBar",
                    "headersRow",
                    "selectAllLink",
                    "unSelectAllLink"]

  static values = {
    counterUrl: String,
    selectedItemsIds: Array,
    selectableItemsIds: Array
  }

  get selectedItemsCount() { return this.#compactArray(this.selectedItemsIdsValue).length }
  get anySelectedItem() { return this.selectedItemsCount > 0 }
  get currentPageSelectableItems() { return this.itemInputTargets.filter(checkbox => { return !checkbox.disabled }).map(adInput => adInput.value) }

  connect() {
    if (!this.hasItemInputTarget) return; // no items to select

    this.setHeaderCheckboxState()
    this.#handleSelection(this.selectedItemsIdsValue)
  }

  selectAllItems() {
    const newSelection = this.selectableItemsIdsValue
    const headerChecked = true
    const headerIndeterminate = false

    this.#handleHeaderDisplay(headerChecked, headerIndeterminate)
    this.#handleSelection(newSelection)
  }

  unSelectAllItems() {
    const newSelection = []
    const headerChecked = false
    const headerIndeterminate = false

    this.#handleHeaderDisplay(headerChecked, headerIndeterminate)
    this.#handleSelection(newSelection)
    this.#handleTableCheckboxes(false)
  }

  selectedItemsIdsValueChanged() {
    document.querySelectorAll("input.selectable_table_selected_items_ids").forEach((input) => {
      input.value = this.selectedItemsIdsValue
    })
  }

  clickCheckbox(event) {
    const checkbox = event.currentTarget
    let selectedItems = this.selectedItemsIdsValue

    if (checkbox.checked) {
      selectedItems.push(checkbox.value)
    } else {
      selectedItems = selectedItems.filter(item => item !== checkbox.value.toString())
    }

    this.#handleSelection(selectedItems)
    this.setHeaderCheckboxState()
  }

  clickHeaderCheckbox(event) {
    const headerCheckbox = event.currentTarget
    let newSelection;
    const selectedItems = this.selectedItemsIdsValue
    const headerChecked = headerCheckbox.checked
    const headerIndeterminate = headerCheckbox.indeterminate

    if (headerChecked) {
      newSelection = selectedItems.concat(this.currentPageSelectableItems.filter((item) => selectedItems.indexOf(item) < 0))
    } else {
      newSelection = []
    }
    this.#handleHeaderDisplay(headerChecked, headerIndeterminate)
    this.#handleSelection(newSelection)
    this.#handleTableCheckboxes(headerChecked)
  }

  setHeaderCheckboxState() {
    if (!this.hasHeaderCheckboxTarget) { return }

    let indeterminate;
    let checked;

    if (this.#allPageItemsSelected() && this.selectedItemsIdsValue.length != 0 ) {
      indeterminate = false
      checked = true
    } else if (this.anySelectedItem) {
      indeterminate = true
      checked = true
    } else {
      indeterminate = false
      checked = false
    }

    this.#handleHeaderDisplay(checked, indeterminate)
  }

  // private

  #handleTableCheckboxes(checked) {
    this.itemInputTargets.forEach(checkbox => {
      if (!checkbox.disabled) {
        checkbox.checked = checked;
        checkbox.dispatchEvent(new Event('change'))
      }
    })
  }

  #handleHeaderDisplay(checked, indeterminate) {
    this.headerCheckboxTargets.forEach((header) => {
      header.checked = checked;
      header.indeterminate = indeterminate;
    })

    if (checked) {
      this.#display(this.topControlBarTarget)
      this.#hide(this.headersRowTarget)
    } else {
      this.#hide(this.topControlBarTarget)
      this.#display(this.headersRowTarget)
    }
  }

  #handleSelection(selection) {
    this.#updateSelectedItems(selection)
    this.#updatePaginationParams()
    this.#updateCounter()
    this.#displaySelectAllLink()
  }

  #displaySelectAllLink() {
    const allTableItemsSelected = this.#allSelectableAreSelected(this.selectableItemsIdsValue, this.selectedItemsIdsValue)

    if (allTableItemsSelected) {
      this.#hide(this.selectAllLinkTarget)
      this.#display(this.unSelectAllLinkTarget)
    } else if (this.#allPageItemsSelected()) {
      this.#display(this.selectAllLinkTarget)
      this.#hide(this.unSelectAllLinkTarget)
    } else {
      this.#hide(this.selectAllLinkTarget)
      this.#hide(this.unSelectAllLinkTarget)
    }
  }

  #updateSelectedItems(selection) {
    this.selectedItemsIdsValue = this.#compactArray(selection)
  }

  async #updateCounter() {
    const params = { items_ids: this.selectedItemsIdsValue }
    post(this.counterUrlValue, { responseKind: "turbo-stream", body: JSON.stringify(params) })
  }

  #updatePaginationParams() {
    const paginationFrames = document.querySelectorAll("nav.pagination")

    paginationFrames.forEach((paginationFrame) => {
      const paginationLinks = paginationFrame.querySelectorAll('a')
      paginationLinks.forEach(link => this.#updateSelectedItemsLink(link))
    })
  }

  #updateSelectedItemsLink(link) {
    const url = new URL(link.href);
    url.searchParams.delete('selected_items_ids')
    url.searchParams.append('selected_items_ids', this.selectedItemsIdsValue);
    link.href = url.href
  }

  #allSelectableAreSelected = (selectable, selected) => selectable.every(id => selected.includes(id));

  #hide(element) { element.classList.add('hidden') }
  #display(element) { element.classList.remove('hidden') }
  #allPageItemsSelected() { return this.#allSelectableAreSelected(this.currentPageSelectableItems, this.selectedItemsIdsValue) }

  #compactArray(array) { return array.filter(element => { return element !== '' }) }
}
