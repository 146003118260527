import { Controller } from "stimulus";
import { post } from '@rails/request.js'
import { put } from '@rails/request.js'

export default class extends Controller {
  static targets = ["form"]

  submit() {
    const http_method = this.formTarget.getAttribute("method")

    if (http_method == 'post') {
      this.postMethodSubmit()
    } else if (http_method == 'put' ) {
      this.putMethodSubmit()
    }
    else {
      this.getMethodSubmit()
    }
  }

  search() {
    if (event.target.value && event.target.value.length < 3) {
     return;
    }
    setTimeout(() => {
      this.submit()
    }, 500)
  }

  reset() {
    const event = new CustomEvent("reset")
    window.dispatchEvent(event)
    this.formTarget.reset();
    this.submit();
  }

  postMethodSubmit() {
    const url = this.formTarget.getAttribute("action")
    const formData = new FormData(this.formTarget);

    post(url, { responseKind: "turbo-stream", body: formData } )
  }

  putMethodSubmit() {
    const url = this.formTarget.getAttribute("action")
    const formData = new FormData(this.formTarget);

    put(url, { responseKind: "turbo-stream", body: formData } )
  }

  getMethodSubmit() {
    const url = new URL(this.formTarget.getAttribute("action"));
    const formData = new FormData(this.formTarget);
    const frame = document.querySelector(`turbo-frame#${this.formTarget.dataset.targetedTurboFrame}`)

    url.search = new URLSearchParams(formData).toString();
    frame.src = url
    frame.reload()
  }
}
