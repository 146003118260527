import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "actionsPanel" ]

  actionsPanelTargetConnected() {
    const rowCells = this.actionsPanelTarget.parentNode.querySelectorAll('.table-cell')
    let lastCell = rowCells[rowCells.length - 1];

    if (lastCell) {
      this.insertWrapper(lastCell)
    }
  }

  connect() {
    this.hiddenCssClass = 'hidden'
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  hover(event) {
    event.preventDefault()
    this.actionsPanelTarget.classList.remove(this.hiddenCssClass)
  }

  out(event) {
    event.preventDefault()
    this.actionsPanelTarget.classList.add(this.hiddenCssClass)
  }

  insertWrapper(cell) {
    const wrapper = document.createElement('div');
    wrapper.className = 'relative';

    cell.childNodes.forEach(childNode => {
      // childNodes property will return text nodes inserted by the browser into a document
      if (childNode.nodeName != "#text") {
        wrapper.appendChild(childNode);
      }
    });

    cell.appendChild(wrapper);
    const panelClone = this.actionsPanelTarget.cloneNode(true)
    this.actionsPanelTarget.remove()
    wrapper.appendChild(panelClone)
  }
}
