// Bugsnag
import "~/plugins/bugsnag"

// Rails UJS
import Rails from "@rails/ujs"
if (!window._rails_loaded) { Rails.start() } // prevent double-load: https://github.com/ElMassimo/jumpstart-vite/commit/f25668a989c3bfd50b9063ee99645771c7a56101#r84401032

// ActiveStorage
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

// Trix
import Trix from "trix"
import "trix/dist/trix.css"
document.addEventListener("trix-before-initialize", () => {
  console.log("Trix loaded!", Trix.config)
})

// FontAwesome
import "@fortawesome/fontawesome-free/css/all.css"

// Turbo
import "@hotwired/turbo-rails"
import { turboConfirmDialog } from "~/utils/turbo_confirm_dialog.js"
turboConfirmDialog()

// Stimulus
import { Application } from "stimulus"
import { registerControllers } from "stimulus-vite-helpers"

const stimulus = Application.start()
registerControllers(stimulus, import.meta.glob("~/controllers/**/*_controller.js", { eager: true }))
registerControllers(stimulus, import.meta.glob("~/../components/**/*_controller.js", { eager: true }))

// Misc
import { disableInputScroll } from "~/utils/disable_input_scroll"

const pageInit = () => {
  disableInputScroll();
}
if (document.readyState === "complete") pageInit()
else document.addEventListener("turbo:load", pageInit)
