import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "dropdown", "menu", "button" ]

  connect() {
  }

  toggleMenu() {
    this.toggleVisibility()
  }

  closeMenu(event) {
    if ( event.target.closest("button") !== this.buttonTarget ) {
      this.dropdownTarget.classList.remove("show")
      this.hideMenu()
    }
  }

  toggleVisibility() {
    if (this.isVisible()) {
      this.hideMenu()
    } else {
      this.displayMenu()
    }

    this.dropdownTarget.classList.toggle("show")
  }

  isVisible() {
    return this.menuTarget.style.visibility === "visible"
  }

  hideMenu() {
    this.menuTarget.style.visibility = "hidden"
  }

  displayMenu() {
    this.menuTarget.style.visibility = "visible"
  }
}
