import { Controller } from '@hotwired/stimulus';
import { getUserConfirmation } from "~/utils/confirmation_dialog";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

export default class extends Controller {
  static targets = ['container', 'background']

  static values = {
    mouseDownOnContainer: Boolean,
    requireConfirmation: Boolean,
    backgroundPageId: String,
    backlink: String,
    redirectWhenClosed: Boolean
  }

  connect() {
    // Allow user to close by clicking on background
    this.allowBackgroundClose = true
    disableBodyScroll(this.element)
  }

  disconnect() {
    this.closeModal()
  }

  close(e) {
    if (this.requireConfirmationValue === true) {
      getUserConfirmation()
      .then((userConfirmed) => {
        if (userConfirmed) {
          this.removeModal()
        }
      })
    } else {
      this.removeModal()
    }
  }

  removeModal() {
    if (this.hasBackgroundPageIdValue && this.hasBacklinkValue) {
      const originPageIsBehindLightbox = document.getElementById(this.backgroundPageIdValue)
      if (originPageIsBehindLightbox) {
        this.closeModal()
        this.replaceUrl()
      } else {
        this.redirectToBacklink()
      }
    } else if (this.redirectWhenClosedValue) {
      this.redirectToBacklink()
    }
    else {
      this.closeModal()
    }
  }

  replaceUrl() {
    window.history.pushState({}, null,this.backlinkValue)
  }

  redirectToBacklink() {
    window.location.replace(this.backlinkValue)
  }

  closeModal() {
   // Remove the modal
   this.element.remove();
   enableBodyScroll(this.element)

    // Hide the background
    if (this.hasBackgroundTarget) {
      this.backgroundTarget.classList.add(this.toggleClass);
    }
  }

  mousedownOnContainer(e) {
    this.mouseDownOnContainerValue = (e.target === this.containerTarget)
  }

  closeBackground(e) {
    if (this.allowBackgroundClose && e.target === this.containerTarget && this.mouseDownOnContainerValue == true) {
      this.close(e);
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
      this.close(e);
    }
  }
}
