import { Controller } from "stimulus"

const activeClassList = [
  'bg-purple-200',
  'border-transparent'
]

export default class extends Controller {
  static targets = ["searchInput"]
  static values = { active: Boolean }

  activeValueChanged() {
    this.#setActiveStyle()
  }

  connect() {
    this.setActiveState()
  }

  setActiveState() {
    this.activeValue = this.searchInputTarget.value !== ''
  }

  #setActiveStyle() {
    if (this.activeValue === true) {
      this.searchInputTarget.classList.add(...activeClassList)
    } else {
      this.searchInputTarget.classList.remove(...activeClassList)
    }
  }
}
