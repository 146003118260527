async function getUserConfirmation() {
  const dialog = document.getElementById("confirmationDialog");
  const cancelActionButton = document.getElementById("confirmationDialog-cancel-button");
  const confirmActionButton = document.getElementById("confirmationDialog-action-button");

  let userConfirms = new Promise(function(resolve) {
    cancelActionButton.addEventListener("click", () => {
      closeDialogAndResolve(resolve, dialog, false)
    });

    confirmActionButton.addEventListener("click", () => {
      closeDialogAndResolve(resolve, dialog, true)
    });
  });

  dialog.showModal();

  return await userConfirms;
}

function closeDialogAndResolve(resolve, dialog, userConfirmed) {
  dialog.close();
  resolve(userConfirmed);
}

export { getUserConfirmation };
