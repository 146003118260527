import { post, put } from '@rails/request.js'
import { debounce } from 'lodash'

const turboSubmitForm = debounce((form) => {
  const httpMethod = form.getAttribute("method");

  if (httpMethod === "post") {
    methodSubmit(form, post);
  } else if (httpMethod === "put") {
    methodSubmit(form, put);
  } else {
    form.requestSubmit();
  }
}, 650); // 650ms debounce time

const methodSubmit = (form, method) => {
  const url = form.getAttribute("action");
  const formData = new FormData(form);

  method(url, { responseKind: "turbo-stream", body: formData });
}

export { turboSubmitForm };
