import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "actionsPanel" ]

  connect() {
    this.hiddenCssClass = 'hidden'
  }

  hover(event) {
    event.preventDefault()
    this.actionsPanelTarget.classList.remove(this.hiddenCssClass)
  }

  out(event) {
    event.preventDefault()
    this.actionsPanelTarget.classList.add(this.hiddenCssClass)
  }
}
