import { Controller } from "stimulus"
import { put } from '@rails/request.js'

export default class extends Controller {
  connect() {
    const paginationLinks = this.element.querySelectorAll("a");

    paginationLinks.forEach(link => link.addEventListener("click", this.submit))
  }

  submit(event) {
    event.preventDefault()
    const url = new URL(event.currentTarget.href)
    const path = url.origin + url.pathname
    const urlParams = new URLSearchParams(url.search)
    const urlParamsObject = Object.fromEntries(urlParams)
    const cleanParams = Object.assign(urlParamsObject)

    put(
      path,
      {
        body: cleanParams,
        responseKind: "turbo-stream"
      }
    )
  }
}
