import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radioButton"]

  toggleChecked() {
    const toggledChecked = !this.radioButtonTarget.checked

    document.querySelectorAll('input[type=radio][name="data_request[request_template_situation_slug]"]').forEach((radio) => {
      radio.checked = false
    })

    this.radioButtonTarget.checked = toggledChecked
  }
}
