import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggleable', 'reversable', 'colorReversable', 'hiddable', 'turnable']

  get minHeightToggleClass() { return "h-0" }
  get marginTopToggleClass() { return "mt-2" }
  get rotateReverseClass() { return "fa-rotate-180" }
  get rotateClass() { return "fa-rotate-90" }
  get primaryColorClass() { return "text-primary" }
  get darkColorClass() { return "text-dark" }

  toggle() {
    this.toggleOpen();
  }

  toggleOpen() {
    this.toggleableTargets.forEach(target => {
      target.classList.toggle(this.minHeightToggleClass)
      target.classList.toggle(this.marginTopToggleClass)
    })

    this.reversableTargets.forEach(target => {
      target.classList.toggle(this.rotateReverseClass)
    })

    this.turnableTargets.forEach(target => {
      target.classList.toggle(this.rotateClass)
    })

    this.hiddableTargets.forEach(target => {
      target.classList.toggle('hidden')
    })

    this.colorReversableTargets.forEach(target => {
      if (target.classList.contains(this.primaryColorClass)) {
        target.classList.remove(this.primaryColorClass)
        target.classList.add(this.darkColorClass)
      } else {
        target.classList.add(this.primaryColorClass)
        target.classList.remove(this.darkColorClass)
      }
    })
  }
}
