import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["assigneeInSelect", "assigneeInList"]

  onRemoveFromList({ params: { userId }}) {
    this.#elementInList(userId).classList.toggle("hidden")
    this.#inputField(userId).checked = false
    this.#inputField(userId).dispatchEvent(new Event("change", { bubbles: true, composed: true }))
  }

  onChangeInSelect(event) {
    const userId = event.target.value
    const selected = event.target.checked
    let element = this.#elementInList(userId)
    if (element) {
      element.classList.toggle("hidden", !selected)
    }
  }

  #elementInList(userId) {
    return this.assigneeInListTargets.find(element =>
      element.dataset.userId === userId.toString()
    )
  }

  #inputField(userId) {
    return this.assigneeInSelectTargets.find(input =>
      input.value === userId.toString()
    )
  }
}
